import React from "react";

import "./Contact.css";
function Contact() {
  return (
    <div className="contact_main cursor ">
      <div className="color-white f-1-2 f-500"> Hermann Gmeiner School</div>
      <div className=" mt-4">
        <div className="d-flex  gap-3 mt-3 overflow-wrap-anywhere">
          <div>
            <img
              src={`https://www.pathways.in/assets/img/location_white.svg`}
              className="contact-icon"
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="color-white f-1">
              Anjar-Bhuj Highway, At. Village Gada, Po. Madhapar,
            </div>
            <div className="color-white f-1">Pin Code – 370 020,</div>
            <div className="color-white f-1">Dist. Bhuj (Kutch – Gujarat)</div>
          </div>
        </div>
        <div className="d-flex  gap-3 mt-3 overflow-wrap-anywhere">
          <div>
            <img
              src={`https://www.pathways.in/assets/img/email_us_white.svg`}
              className="contact-icon"
            />
          </div>
          <div className="color-white f-1">
          principal.bhuj@hermann-gmeiner-edu.org
          </div>
        </div>
        <div className="d-flex  gap-3 mt-3 overflow-wrap-anywhere">
          <div>
            <img
              src={`https://www.pathways.in/assets/img/phone_icon_white.svg`}
              className="contact-icon"
            />
          </div>
          <div className="color-white f-1">73834 96672</div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
